/**
 * Log application open and close activity (apps as per requirement(s)) along with login logout
 */
 export default class ApplicationLogger {
  static activeApplications = [];
  static allowedApps = ["PolicyCentral", "Home"];
  static mappedApps = {
    PolicyCentral : "Policy Central",
    Home : "Skydrive",
  }
  static getLogUrl(activity) {
    return ["v1", `/auditlog/activity/${activity}`, {}, "post"];
  }
  static getAppActivity(isOpen, appName){
    return `${isOpen && 'open' || 'close'} ${this.mappedApps[appName] || appName}`
  }
  static logLoginLogout(core, isLogin) {
    core
      .make("oxzion/restClient")
      .request(...this.getLogUrl((isLogin && "login") || "logout"));
  }
  static openApp(core, appName) {
    if (
      !this.allowedApps.includes(appName) ||
      this.activeApplications.includes(appName)
    )
      return;
    this.activeApplications.push(appName);
    core
      .make("oxzion/restClient")
      .request(...this.getLogUrl(this.getAppActivity(true, appName)));
  }
  static closeApp(core, appName) {
    if (!this.allowedApps.includes(appName)) return;
    const idx = this.activeApplications.indexOf(appName);
    if(idx === -1) return;
    this.activeApplications.splice(idx, 1);
    core
      .make("oxzion/restClient")
      .request(...this.getLogUrl(this.getAppActivity(false, appName)));
  }
}