module.exports = {
    development : false,	
    wrapper: {
      url: "https://skywardskydrive.com:9080/"
  },
ui: {
      url: "https://skywardskydrive.com/"
  },
  api: {
      url: "https://skywardskydrive.com:9080/"
 },
  desktop: {
    settings: {
      font: "Roboto",
      theme: "Skyward",
    },
  },
};
