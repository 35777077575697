import PanelItem from "../panel-item";
import { h } from "hyperapp";
import Swal from "sweetalert2";

export default class NotificationCenterPanel extends PanelItem {
  init() {
    if (this.inited) {
      return;
    }
    this.helper = this.core.make("oxzion/restClient");
    this.splash = this.core.make("oxzion/splash");
    this.profileDetails = this.core.make("oxzion/profile").get();
    this.userAcountInfo = this.profileDetails?.key?.active_account;
    this.appList = this.profileDetails?.key?.apps?.filter(
      (i) => i.type == 2 && i.logo
    );
    this.appList = [{ name: "taskapp1" }];
    this.NotificationCenterActions = super.init(
      {
        count: 0,
        showPanel: false,
        selectedTab: "assignments",
        taskList: [],
        rygFocus: "red",
        assignments_rygCount_red: 0,
        assignments_rygCount_yellow: 0,
        assignments_rygCount_green: 0,
        followups_rygCount_red: 0,
        followups_rygCount_yellow: 0,
        followups_rygCount_green: 0,
        expandItem: undefined,
        loading: true,
        lastTaskFetch: null,
      },
      {
        toggleNotificationPanel: this.toggleNotificationPanel,
        updateState: this.updateState,
        expandTaskItem: this.expandTaskItem,
        taskItemClick: this.taskItemClick,
        wireAction: this.wireAction,
      }
    );

    this.taskItemActions = [
      {
        name: "edit",
        icon: "fa-solid fa-pencil",
      },
      {
        name: "snooze",
        icon: "fa-solid fa-clock",
      },
      {
        name: "delete",
        icon: "fa-solid fa-trash",
      },
    ];
  }

  destroy() {
    this.interval = clearInterval(this.interval);
    super.destroy();
  }

  fetchRYGTaskCount(state, rygStatus) {
    let filter = [];
    switch (state.selectedTab) {
      case "assignments":
        filter.push(["assignedto", "==", "session:useruuid"]);
        break;
      case "followups":
        filter.push(["createdby", "==", "session:name"]);
        break;
      default:
        break;
    }
    filter.push("AND");
    filter.push(["rygStatus", "==", rygStatus]);
    this.makeRequest({
      field: "id",
      filter: JSON.stringify(filter),
    }).then((result) => {
      this.NotificationCenterActions.updateState({
        [state.selectedTab + "_rygCount_" + rygStatus.toLowerCase()]:
          result.reduce((a, b) => a + b, 0),
      });
    });
  }

  fetchTaskCount() {
    this.makeRequest({
      field: "id",
      filter: JSON.stringify(["assignedto", "==", "session:useruuid"]),
    }).then((result) =>
      this.NotificationCenterActions.updateState({
        count: result.reduce((a, b) => a + b, 0),
      })
    );
  }

  fetchTasks(state) {
    setTimeout(() => {
      this.splash.show(
        document.getElementsByClassName("notificationCenter-panel")[0],
        "Getting your tasks"
      );
    }, 100);
    let filter;
    switch (state.selectedTab) {
      case "assignments":
        filter = ["assignedto", "==", "session:useruuid"];
        break;
      case "followups":
        filter = ["createdby", "==", "session:name"];
        break;
      default:
        filter = [];
        break;
    }
    filter.push("AND");
    filter.push(["status", "NOT LIKE", "Completed"]);
    let config = {
      list: [
        "appName",
        "createdby",
        "assignedToName",
        "assignedTo",
        "name",
        "ryg_status",
        "next_action_date",
        "status",
        "uuid",
      ].toString(),
      filter: JSON.stringify(filter),
      sort: { next_action_date: "desc" },
    };
    this.makeRequest(config).then((response) => {
      let taskList = [];
      response.forEach((tasks) => tasks?.length > 0 && taskList.push(...tasks));
      this.NotificationCenterActions.updateState({
        taskList: taskList.slice(0, 10),
        masterTaskList: taskList,
        lastTaskFetch: Date.now(),
        showPanel: true,
        loading: false,
      });
      this.splash.destroy();
    });
  }

  async makeRequest(config) {
    const apiPromises = this.appList.map((appName) => {
      return this.helper
        .request(
          "v1",
          "analytics/query/preview",
          {
            // datasource_id: userAcountInfo?.preferences?.elastic_datasource_id
            datasource_id: "e6eb933f-3d98-44f1-8a43-9e44f51ecefb",
            configuration: {
              app_name: appName.name.toLowerCase(),
              ...config,
            },
          },
          "post"
        )
        .then((response) => {
          if (response.status == "success") return response?.data?.result;
          else return null;
        })
        .catch((error) => {
          console.error(error);
          return null;
        });
    });
    return Promise.all(apiPromises).then((result) => result);
  }

  toggleNotificationPanel = (state) => {
    if (
      !state.showPanel &&
      (!state.lastTaskFetch || Date.now() > state.lastTaskFetch + 60000)
    ) {
      this.fetchTasks(state);
    }
    return {
      ...state,
      showPanel: !state.showPanel,
    };
  };

  updateState = (data) => data;

  expandTaskItem = (data) => (state) => ({
    ...state,
    expandItem:
      data.expandItem == state.expandItem ? undefined : data.expandItem,
  });

  taskItemClick = (action) => async (state) => {
    switch (action) {
      case "edit":
        break;
      case "snooze":
        let { value: snoozeValue } = await Swal.fire({
          title: "After how many days would you want to like be reminded?",
          input: "select",
          inputOptions: {
            Days: {
              day1: "1",
              day2: "2",
            },
            Weeks: {
              week1: "1",
              week2: "2",
            },
            Months: {
              month1: "1",
              month2: "2",
            },
            custom: "Choose Date",
          },
          confirmButtonColor: "#275362",
          cancelButtonColor: "#7b7878",
          showCancelButton: true,
        });

        if (snoozeValue != "custom") {
          Swal.fire(`You will be reminded in: ${snoozeValue}`);
        } else {
          snoozeValue = await Swal.fire({
            title: "Select Date",
            html: '<input id="snooze-task-date" type="datetime-local">',
            focusConfirm: false,
            preConfirm: () => {
              return document.getElementById("snooze-task-date").value;
            },
            confirmButtonColor: "#275362",
            cancelButtonColor: "#7b7878",
          });
          Swal.fire(`You will be reminded on: ${snoozeValue.value}`);
        }
        break;
      case "comment":
        break;
      case "delete":
        Swal.fire({
          title: "Are you sure you want to delete the task?",
          position: "center",
          confirmButtonText: "Yes",
          showCancelButton: true,
          confirmButtonColor: "#275362",
          cancelButtonColor: "#7b7878",
        }).then((result) => {
          if (result.value) {
            // logout();
          }
        });
        break;

      default:
        break;
    }
  };

  wireAction = (method) => method;

  render(state, actions) {
    let rygComponent = h(
      "div",
      {
        className: "rygToggle-container",
      },
      [
        h(
          "div",
          {
            className: "rygToggle-r" + (state.rygFocus == "R" ? " focus" : ""),
            oncreate: (el) => this.fetchRYGTaskCount(state, "RED"),
            onclick: () => actions.updateState({ rygFocus: "R" }),
          },
          state[state.selectedTab + "_rygCount_red"]
        ),
        h(
          "div",
          {
            className: "rygToggle-y" + (state.rygFocus == "Y" ? " focus" : ""),
            oncreate: (el) => this.fetchRYGTaskCount(state, "YELLOW"),
            onclick: () => actions.updateState({ rygFocus: "Y" }),
          },
          state[state.selectedTab + "_rygCount_yellow"]
        ),
        h(
          "div",
          {
            className: "rygToggle-g" + (state.rygFocus == "G" ? " focus" : ""),
            oncreate: (el) => this.fetchRYGTaskCount(state, "GREEN"),
            onclick: () => actions.updateState({ rygFocus: "G" }),
          },
          state[state.selectedTab + "_rygCount_green"]
        ),
      ]
    );
    return;
    return super.render("notificationCenter", [
      h(
        "div",
        {
          className: "osjs-panel-notificationCenter",
          oncreate: (el) => this.fetchTaskCount(state),
          onclick: () => actions.toggleNotificationPanel(state),
        },
        [
          h("i", {
            className: "fa-solid fa-bell",
          }),
          h("div", {
            className: "notificationCenter-badge",
            style:
              state.count == 0
                ? {
                    opacity: 0,
                  }
                : null,
            innerHTML: state.count,
          }),
        ]
      ),
      state.showPanel
        ? h(
            "div",
            {
              className: "notificationCenter-panel",
            },
            h(
              "div",
              {
                className: "tab-container",
              },
              [
                h(
                  "div",
                  {
                    onclick: (event) => {
                      actions.updateState({
                        selectedTab: "assignments",
                        taskList: [],
                        loading : true
                      });
                      this.fetchTasks(state);
                    },
                    className:
                      "tab-assignments" +
                      (state.selectedTab == "assignments" ? " selected" : ""),
                  },
                  "Assignments",
                  state.selectedTab == "assignments" ? rygComponent : null
                ),
                h(
                  "div",
                  {
                    onclick: (event) => {
                      actions.updateState({
                        selectedTab: "followups",
                        taskList: [],
                        loading : true
                      });
                      this.fetchTasks(state);
                    },
                    className:
                      "tab-followups" +
                      (state.selectedTab == "followups" ? " selected" : ""),
                  },
                  "Followups",
                  state.selectedTab == "followups" ? rygComponent : null
                ),
              ]
            ),
            state.taskList.length > 0
              ? [
                  h(
                    "div",
                    { className: "task-list" },
                    state.taskList.map((task, index) =>
                      h(
                        "div",
                        {
                          className: "task-item",
                          onclick: () =>
                            actions.expandTaskItem({ expandItem: index }),
                        },
                        [
                          h(
                            "div",
                            {
                              style: {
                                width: "100%",
                                cursor: "pointer",
                              },
                            },
                            [
                              h("div", { className: "title" }, task.name),
                              h(
                                "div",
                                { className: "app" },
                                task.next_action_date
                              ),
                              state.expandItem == index
                                ? h("div", { className: "task-item-actions" }, [
                                    this.taskItemActions.map((action) =>
                                      h("i", {
                                        className: action.icon,
                                        name: action.name,
                                        onclick: () =>
                                          actions.taskItemClick(action.name),
                                      })
                                    ),
                                  ])
                                : null,
                            ]
                          ),
                          h("i", {
                            className:
                              "fa-solid fa-chevron-" +
                              (state.expandItem == index ? "up" : "down"),
                          }),
                        ]
                      )
                    ),
                    h(
                      "button",
                      {
                        className: "view-all-button",
                        onclick: () => {
                          this.core.run("NotificationCenter", {
                            ...state,
                          });
                          return actions.toggleNotificationPanel(
                            state,
                            actions
                          );
                        },
                      },
                      "VIEW All"
                    )
                  ),
                ]
              : h("div", {
                  className: "tasks-completed",
                  innerHTML: state.loading
                    ? ""
                    : "Nothing here. </br> You are all done for the day!",
                })
          )
        : null,
    ]);
  }
}
